<template>
	<div class="trialsee alertDialogPage">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 菜单 -->
		<div style="height: 15px;"></div>
		<div class="menus">
			<div
				:ref="'menu'+key"
				:class="['menu', node_id == item.scsj_experience_node_id ? 'menu--active' : '']"
				v-for="item,key in list"
				:key="item.scsj_experience_node_id"
				@click="handMenu(item, key)"
			>
				{{item.node_name}}
			</div>
		</div>
		
		<!-- 内容 -->
		<div class="content">
			<van-swipe

				:loop="false"
				:initial-swipe="node_key"
				:show-indicators="false"
				@change="handContent"
			>
				<van-swipe-item v-for="item in list" :key="item.scsj_experience_node_id" style="overflow: auto;">
					<div class="opts">
						<div class="opt" v-for="itemContent in item.res" :key="itemContent.id" @click="handSee(itemContent)">
							{{itemContent.name}}
							<img src="@/assets/trial/icon_right2.png" class="opt__btn">
						</div>
					</div>
				</van-swipe-item>
			</van-swipe>
		</div>
		
		<!-- 切换按钮 -->
		<div style="height: 144px;"></div>
		<div class="swbtn">
			<div class="swbtn1" v-if="node_key != 0" @click="handPrev">
				<img src="@/assets/trial/icon_left_abled.png">
			</div>
			<div class="swbtn2" v-if="node_key == 0">
				<img src="@/assets/trial/icon_left_disabled.png">
			</div>
			<div @click="handFinish" class="swbtn3" v-if="node_key == list.length - 1 && condition == 1">
				完成体验
			</div>
			<div class="swbtn1" v-if="node_key != list.length - 1" @click="handNext">
				<img src="@/assets/trial/icon_right_abled.png">
			</div>
			<div class="swbtn2" v-if="node_key == list.length - 1 && condition != 1">
				<img src="@/assets/trial/icon_right_disabled.png">
			</div>
		</div>
		
		<!-- 弹出框 -->
		<!-- <uni-popup ref="alertDialog" type="dialog">
			<uni-popup-dialog
				cancelText="取消"
				confirmText="确定"
				title="完成体验"
				@confirm="handFinishReq">
				<div class="finish__con">
					确定要结束本次试乘试驾吗？
				</div>
			</uni-popup-dialog>
		</uni-popup> -->
		<van-dialog v-model="show" @confirm="handFinishReq" title="完成体验" show-cancel-button>
			<div class="user__inviter">
				确定要结束本次试乘试驾吗？
			</div>
		</van-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			condition: 1,
			number: '',
			node_id: 0,
			node_key: 0,
			list: [],
			show:false
		};
	},
	mounted() {
		if (this.$route.query.number) this.number = this.$route.query.number
		this.getProcess()
	},
	methods: {
		// 体验
		handSee(e) {
			if(e.content_type == 'other'){
				const ss = this.baseUrl2 + '/scsj/scsjsjxq/?point_id=' + e.id
				this.$router.push({path:'/scsjsjxq?url=' + ss})
			}else{
				const ss = this.baseUrl2 + '/scsj/scsjwsyy/?point_id=' + e.id + '#/'
				this.$router.push({path:'/scsjsjxq?url=' + ss})
			}
			// this.$router.push({
			// 	path: `/trial-point?id=${e.id}&type=${e.content_type}`
			// })
		},
		
		// 上一页
		handPrev(e) {
			this.node_key--
			this.node_id = this.list.filter((v, k) => k == this.node_key)[0].scsj_experience_node_id
		},
		
		// 下一页
		handNext(e) {
			this.node_key++
			this.node_id = this.list.filter((v, k) => k == this.node_key)[0].scsj_experience_node_id
		},
		
		// 切换菜单
		handMenu(e, key) {
			this.node_id = e.scsj_experience_node_id
			this.node_key = key
		},
		
		// 切换内容
		handContent(e) {
			this.node_key = e
			this.node_id = this.list.filter((v, k) => k == this.node_key)[0].scsj_experience_node_id
		},
		
		// 完成体验
		handFinish(e) {
			this.show = true
		},
		async handFinishReq(e) {
			const {data: result} = await this.$http.post('/api/scsj/finish_scsj_1_1', {
				number: this.number,
				condition: 2
			})
			if (result.code == 1) {
				this.$toast.success(result.msg)
				this.$router.go(-1)
			} else {
				this.$toast.fail(result.msg)
			}
		},
		
		// 获取详情
		async getProcess(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_process_catalogue', {
				number: this.number
			})
			if (result.code == 1) {
				this.condition = result.data.condition
				result.data.res.experience_point.forEach(v => {
					if (this.node_id == 0) this.node_id = v.scsj_experience_node_id
					this.list.push(v)
				})
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.menus{
	display: flex;
	white-space: nowrap;
	max-width: 100%;
	overflow-x: auto;
	height: 72px;
}
.menu{
	text-align: center;
	line-height: 72px;
	font-size: 36px;
	font-family: HYQiHeiY3-55;
	color: #F3F3F3;
	padding: 0 20px;
}
.menu--active{
	color: #F3C846;
	border-bottom: 1px solid #F3C846;
}
.opt{
	width: 100%;
	box-sizing: border-box;
	height: 208px;
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_see.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	font-size: 46px;
	font-family: HYQiHeiY3-55;
	color: #F3F3F3;
	line-height: 68px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	padding: 0 116px 0 40px;
	position: relative;
	&__btn{
		position: absolute;
		top: 88px;
		right: 40px;
		width: 36px;
		height: 32px;
	}
}
.swbtn{
	background: #191919;
	position: fixed;
	bottom: 0;
	padding: 22px 40px;
	padding-bottom: calc(constant(safe-area-inset-bottom) + 22px);
	padding-bottom: calc(env(safe-area-inset-bottom) + 22px);
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	.swbtn1{
		width: 196px;
		height: 92px;
		background: #FA0037;
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			width: 32px;
			height: 24px;
		}
	}
	.swbtn2{
		width: 196px;
		height: 92px;
		background: #232323;
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			width: 32px;
			height: 24px;
		}
	}
	.swbtn3{
		width: 196px;
		height: 92px;
		background: #FA0037;
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		text-align: center;
		line-height: 92px;
	}
}
.finish__con{
	font-family: HYQiHeiY3-45;
}

.user__inviter{
	font-family: HYQiHeiY3-45;
	text-align: center;
	padding: 20px 0;
}
</style>
